import { Slug } from '@/shared/components/Slug';
import { Headline2, Headline3, 
    // Paragraph1, 
    Paragraph2 } from '@/shared/components/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import SectionLongCard from 'components/Sections/SectionMedia/SectionLongCard';
import { ISectionComponentProps, getPostUrl } from 'components/Sections/utils';
import { useTranslations } from 'contexts/translations';
import IconButton from '@/shared/components/Button/IconButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
// import useNewsCard from '../hooks';
import {Carousel} from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { useFlfNewsStyles } from '../styles';
import { useMemo, useState } from 'react';
import useElementSize from '@/shared/hooks/useElementSize';
import Container from '@material-ui/core/Container'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ITheme } from '@/shared/styles/MuiTheme';
import { useIntl } from 'react-intl';
import { sectionMessages } from 'components/Sections/messages';
import ImgLoader from '@/shared/components/Img/ImgLoader';
import ClubResponsiveFallback from '@/shared/components/Img/ClubResponsiveFallback';
import SecondaryButton from '@/shared/components/Button/SecondaryButton';
import { useSystemPagePath } from 'utils/hooks';
import { WebsitePageType } from '@/shared/models/Website';
import EmptyFlfNews from 'components/Sections/Placeholders/EmptyFlfNews';
import ElementLink from '@/shared/components/ElementLink/ElementLink';
import { useAuth } from 'contexts/auth';
// import parseHtml from 'html-react-parser';
import { IPost } from '@/shared/models/Post';


const iconPath = `${process.env.NEXT_PUBLIC_CDN}/${process.env.NEXT_PUBLIC_ASSETS_IMAGES}`


export default function FlfNewsSection(props: ISectionComponentProps) {
    const { posts } = props.data
    const { timePassedFormatter } = useTranslations()
    const { user } = useAuth()
    const { isPreview } = props
    const isTopNavVisible = isPreview ? false : Boolean(user)
    const classes = useFlfNewsStyles({ isTopNavVisible })
    const [currentSlide, setCurrentSlide] = useState(0)
    const [carouselRef, { height }] = useElementSize()
    const carouselItems = useMemo(() => posts.slice(0, 4), [posts])
    const restNews = useMemo(() => posts.slice(4), [posts])
    const currentNews = useMemo(() => carouselItems[currentSlide], [currentSlide, carouselItems])
    const isMobileView = useMediaQuery<ITheme>((theme) => theme.breakpoints.down('sm'))
    const intl = useIntl()
    const newsLink = useSystemPagePath(WebsitePageType.POSTS)

    function getPostImageUrl(post: IPost): string {
        const imageContent = post.contents?.find(c => c.layout === 'images')
        if (imageContent?.layout === 'images') {
            return imageContent.content?.images[0]?.url
        }
        return ''
    }

    if(posts.length === 0) {
        return (
            <EmptyFlfNews/>
        )
    }
    return (
        <Box>
            <div className={classes.carouselWrapper}>
                <Carousel
                    swipeScrollTolerance={100}
                    preventMovementUntilSwipeScrollTolerance
                    showIndicators={isMobileView && carouselItems.length > 1}
                    onChange={(index) => setCurrentSlide(index)}
                    selectedItem={currentSlide}
                    showStatus={false}
                    showArrows={false}
                    showThumbs={false}
                >
                    {carouselItems.map(e => (
                        <ElementLink key={e.id} link={getPostUrl(currentNews)}>
                            <div className={classes.carouselImageWrapper} key={e.id} ref={carouselRef}>
                                <ImgLoader
                                    src={getPostImageUrl(e)}
                                    className={classes.carouselImage}
                                    failoverComponent={
                                        <ClubResponsiveFallback
                                            className={classes.carouselImage}
                                        />
                                    }
                                    useNextImage={true}
                                />
                            </div>
                        </ElementLink>
                    ))}
                </Carousel>

                <Container maxWidth='lg' className={classes.contentContainer}>
                    <Box className={classes.heroContent} display='flex' flexDirection='column' justifyContent='flex-end'>
                        <ElementLink link={getPostUrl(currentNews)}>
                            <Box display='flex' flexDirection='column' justifyContent='flex-end'>
                                <Box pb={4}>
                                    <Slug imgSrc={`${iconPath}/websites/Time.svg`} text={<Paragraph2 isAccent><b>{`${timePassedFormatter(currentNews.publication_date)}`}</b></Paragraph2> } />
                                </Box>
                                <Box className={classes.carouselTextColor} pb={4}>
                                    <Headline2>
                                        {currentNews.title}
                                    </Headline2>
                                </Box>
                                <Box className={classes.carouselTextColor} pb={4}>
                                    {/* <Paragraph1>
                                        {parseHtml(currentNews.description_plain || '')}
                                    </Paragraph1> */}
                                </Box>
                            </Box>
                        </ElementLink>
                        {carouselItems.length > 1 && (
                            <Box className={`${classes.controls} ${classes.carouselTextColor}`}>
                                <IconButton disabled={currentSlide === 0} key={1} style={{ borderColor: '#FFFFFF' }} onClick={(e) => {
                                    e.preventDefault()
                                    setCurrentSlide(prev => prev - 1)
                                }}><ArrowBackIcon htmlColor={'#FFFFFF'} /></IconButton>
                                <Box mr={2}/>
                                <IconButton disabled={currentSlide === carouselItems.length - 1} key={2} style={{ borderColor: '#FFFFFF' }} onClick={(e) => {
                                    e.preventDefault()
                                    setCurrentSlide(prev => prev + 1)
                                }}><ArrowForwardIcon htmlColor={'#FFFFFF'} /></IconButton>
                                <Box mr={2}/>
                                <Headline3>{`${currentSlide+1}/${carouselItems.length}`}</Headline3>
                            </Box>
                        )}
                    </Box>
                </Container>
            </div>
            <div style={{ marginBottom: height - 48 }} />
            {restNews.length > 0 && (
                <>
                    <Box py={4}/>
                    <Box key={2} pb={4}>
                        <Headline3>{intl.formatMessage(sectionMessages['news.label'])}</Headline3>
                    </Box>
                    <Grid container spacing={4}>
                        {restNews.map(element => (
                            <Grid key={element.id} item lg={6} sm={12} xs={12}>
                                <SectionLongCard
                                    slug={<Slug imgSrc={`${iconPath}/websites/Time.svg`} text={<Paragraph2 isAccent><b>{`${timePassedFormatter(element.publication_date)}`}</b></Paragraph2>}/>}
                                    link={getPostUrl(element)}
                                    title={element.title}
                                    media={getPostImageUrl(element)}
                                    useNextImage={true}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <Box py={2}/>
                    <Box display={'flex'} alignItems='center' justifyContent={'center'}>
                        <SecondaryButton applyBackgroundColor href={newsLink}>{intl.formatMessage(sectionMessages['website.view_more'])}</SecondaryButton>
                    </Box>
                </>
            )}
        </Box>
    )
}